

#professional div p.company a {
  color: var(--s500);
}

section#contact .btn-social.btn-outline {
  color: white;
  border: 2px solid white;
}

section#contact .btn-social.btn-outline:hover {
  color: var(--s400);
  border: 2px solid var(--s400);
}

.main-contact a {
	text-shadow: none;
}

.main-contact ul li a.btn-social.btn-outline {
  border: 2px solid var(--p500);
}

.main-contact ul li a.btn-social.btn-outline i {
  color: var(--p500);
}

.main-contact ul li:hover a.btn-social.btn-outline {
  border: 2px solid var(--s500);
  background: var(--s500);
}

.main-contact ul li:hover a.btn-social.btn-outline i {
  color: black;
}

.main-contact ul li a:hover, .contact a:hover {
	text-decoration:none;
	text-shadow: none;
  background: none;
}

/* #side-menu ul li a:hover {
	background-color: transparent;
	color: var(--s500);
  transition: all ease-in-out .2s;
} */

/* #side-menu  ul li.page-scroll.active a {
	color: var(--s500);
} */

header, footer {
	background-size: cover;
}

.display {
  color: white;
  font-size: var(--font-sm);
}

.main-contact {
	margin-top:30px;
}

.main-contact #display {
	margin-top:30px;
	font-size: var(--font-xs);
}

.profile {
  max-height: 35rem;
  margin-bottom: 6rem;
  box-shadow: 0 0 15px black;
}

.photo {
  border: 10px solid white;
  border-bottom: 50px solid white;
}

.tilted {
  transform: rotate(3deg);
  transition: all 1s ease-out;
}

.tilted:hover {
  transform: rotate(0);
  transition: all .1s ease-in;
}

.img-shadow {
	box-shadow: 0px 0px 25px black;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-image: url("../../img/backgrounds/background.webp");
  background-color: #292825;
}

/* Do not display animations on mobile devices */

section {
  color: white;
  padding-top: 5rem;
  text-shadow: 0 0 10px black;
}

section h2 {
  padding: 2rem;
}

#skills .skills-cards-container .skills-card ul {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 720px) {

	body{
		min-height: 120vw
	}

	.img-responsive {
		max-width: 75%;
	}
}

@media (min-width: 721px) {

  #skills .skills-cards-container {
    max-width: var(--width-limit);
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }

  #skills .skills-cards-container .skills-card {
    flex: 1;
  }

	ul.powered li a img:hover{
		filter: none;
    mix-blend-mode: normal;
	}
}

@media (max-width: 1280px) {
  #skills .skills-cards-container {
    display: flex;
    flex-direction: column;
  }
}

.skills {
  font-size: var(--font-sm);
}

#skills h2 {
  color: var(--p500);
}

#skills .section-content {
  padding-top: 3rem;
}

.skillstitle {
  padding: 1rem;
  text-align: center;
  font-size: var(--font-md);
  font-weight: bold;
  border: 2px solid var(--p200);
  color: var(--p500);
  border-radius: 3px;
}

.skills > ul {
  padding: 0;
}

.skills > ul > li {
  list-style: none;
  line-height: 2em;
  color: #f9f9f9;
  font-size: var(--font-xs);
  text-align: center;
}

#contact .contact-link {
  color: var(--ps00);
}

#contact .contact-link:hover {
    color: var(--p500);
}

.contact-link {
  text-decoration: none;
  color: var(--s500);
}

.contact-link:hover {
  text-decoration: none;
  color: var(--s500);
}

.hidden-text {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.separator {
  margin: 15px 0;
}

.star-separator{
text-align:center;
}

#main {
 background: transparent;
 text-shadow: 0px 0px 10px black;
}
#display {
  color: #CCC;
  margin-top: 20px;
}

#professional {
 background: transparent;
 text-shadow: 0px 0px 10px black;
}

#professional h2 {
  color: var(--p500);
}

/* Regular entry */

#professional .entry {
  margin: 4rem auto;
}

#professional .entry p.position {
  font-size: var(--font-md);
  margin: 1rem;
  color: var(--p500);
  font-weight: bold;
}

#professional .entry p.company a {
  color: var(--p400);
  font-size: var(--font-sm);
}

#professional .entry p.company a:hover {
  color: var(--s500);
  font-size: var(--font-sm);
}

#professional .entry p.date {
  color: var(--n800);
  font-style: normal;
}

/* Current Entry (empathized) */

#professional .entry.current {
  background-color: #FFFFFF33;
  margin: 4rem auto;
  padding: 2rem;
}

#professional .entry.current p.position {
  font-size: var(--font-lg);
}

#professional .entry.current p.company {
  font-size: var(--font-md);
}

#professional .entry.current p.date {
  font-size: var(--font-md);
}

#academic h2 {
  color: var(--s500);
}

#academic .entry {
  padding: 2rem;
}

#academic .entry p.position {
  font-size: var(--font-md);
  font-weight: bold;
  color: var(--s500);
}

#academic .entry p.date {
  color: var(--n800);
  font-size: var(--font-sm);
}

#contact {
  padding-bottom: 10rem;
}

#contact h2 {
  color: var(--s400);
}

p.small {
  font-size: var(--font-xs);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
hr.star-light,
hr.star-primary {
  padding: 0;
  border: none;
  border-top: solid 5px;
  text-align: center;
  max-width: 250px;
  margin: 25px auto 30px;
}
hr.star-light:after,
hr.star-primary:after {
  content: "\f005";
  font-family: FontAwesome;
  font-display: swap;
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 2em;
  padding: 0 0.25em;
}
hr.star-light {
  border-color: white;
}
hr.star-light-main:after {
  background-color: #454e5f;
  color: white;
}
hr.star-light-professional:after {
  background-color: #626A7A;
  color: white;
}
hr.star-light-academic:after {
  background-color: #74627A;
  color: white;
}
hr.star-light-skills:after {
  background-color: #715173;
  color: white;
}
hr.star-primary {
  border-color: #303D4E;
}
hr.star-primary:after {
  background-color: white;
  color: #303D4E
}
.img-centered {
  margin: 0 auto;
}
header {
  text-align: center;
  color: white;
}
header .container {
  padding-top: 85px;
}
header img {
  display: block;
  margin: 0 auto 20px;
}
header .intro-text .name {
  display: block;
  font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2em;
}
header .intro-text .skills {
  font-size: 1.25em;
  font-weight: 300;
}
@media (min-width: 768px) {
  header .container {
    padding-top: 200px;
    padding-bottom: 100px;
  }
  header .intro-text .name {
    font-size: 2em;
  }
  header .intro-text .skills {
    font-size: 1.75em;
  }
}

@media (max-width: 767px) {
  #side-menu {
    transition: all ease .2s;
    position: fixed;
    padding-left: 3rem;
    /* width: 300px; */
    /* right: -300px; */
    right: -100%;
    width: 100%;
    background-color: transparent;
    border-top: 2px solid var(--p500);
    height: 0;

    transition: all ease-in 2.s;
  }
  
  #side-menu.slided {
    right: 0px;
    background-color: #000000bf;
    height: 100%;
    border-top: 2px solid var(--s500);
  }
  
  /* #page-content {
    transition: all ease 2s;
    left: 0px;
  } */
  
  #page-content.slided {
    /* left: -300px; */
    left: -100%
  }
}

@media (min-width: 767px) {
  #side-menu {
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 4rem;
  }
}

section.success {
  background: #454E5F;
  color: white;
}
section.success a,
section.success a:hover,
section.success a:focus,
section.success a:active,
section.success a.active {
  color: #303D4E;
  outline: none;
}

#portfolio .portfolio-item {
  margin: 0 0 15px;
  right: 0;
}
#portfolio .portfolio-item .portfolio-link {
  display: block;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
#portfolio .portfolio-item .portfolio-link .caption {
  background: rgba(69, 78, 95, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
}
#portfolio .portfolio-item .portfolio-link .caption:hover {
  opacity: 1;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content {
  position: absolute;
  width: 100%;
  height: 20px;
  font-size: var(--font-sm);
  text-align: center;
  top: 50%;
  margin-top: -12px;
  color: white;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content i {
  margin-top: -12px;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content h3,
#portfolio .portfolio-item .portfolio-link .caption .caption-content h4 {
  margin: 0;
}
#portfolio * {
  z-index: 2;
}
@media (min-width: 767px) {

  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
}
.btn-outline {
  color: white;
  font-size: var(--font-sm);
  border: solid 2px white;
  background: transparent;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active {
  background: white;
  border: solid 2px white;
}
.floating-label-form-group {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #eeeeee;
}
.floating-label-form-group input,
.floating-label-form-group textarea {
  z-index: 1;
  position: relative;
  padding-right: 0;
  padding-left: 0;
  border: none;
  border-radius: 0;
  font-size: 1.5em;
  background: none;
  box-shadow: none !important;
  resize: none;
}
.floating-label-form-group label {
  display: block;
  z-index: 0;
  position: relative;
  top: 2em;
  margin: 0;
  font-size: 0.85em;
  line-height: 1.764705882em;
  vertical-align: middle;
  vertical-align: baseline;
  opacity: 0;
  -webkit-transition: top 0.3s ease,opacity 0.3s ease;
  -moz-transition: top 0.3s ease,opacity 0.3s ease;
  -ms-transition: top 0.3s ease,opacity 0.3s ease;
  transition: top 0.3s ease,opacity 0.3s ease;
}
/* .floating-label-form-group::not(:first-child) {
  padding-left: 14px;
  border-left: 1px solid #eeeeee;
} */
.floating-label-form-group-with-value label {
  top: 0;
  opacity: 1;
}
.floating-label-form-group-with-focus label {
  color: #454E5F;
}
form .row:first-child .floating-label-form-group {
  border-top: 1px solid #eeeeee;
}


a.btn-social {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  color: white;
  text-align: center;
  font-size: var(--font-md);
}

.btn:focus,
.btn:active,
.btn.active {
  outline: none;
}
.scroll-top {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 50px;
  height: 50px;
  z-index: 1049;
}
.scroll-top .btn {
  font-size: var(--font-sm);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  line-height: 28px;
}
.scroll-top .btn:focus {
  outline: none;
}
.portfolio-modal .modal-content {
  border-radius: 0;
  background-clip: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  min-height: 100%;
  padding: 100px 0;
  text-align: center;
}
.portfolio-modal .modal-content h2 {
  margin: 0;
  font-size: 3em;
}
.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}
.portfolio-modal .modal-content .item-details {
  margin: 30px 0;
}
.portfolio-modal .close-modal {
  position: absolute;
  width: 75px;
  height: 75px;
  background-color: transparent;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}
.portfolio-modal .close-modal .lr {
  height: 75px;
  width: 1px;
  margin-left: 35px;
  background-color: #303D4E;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  z-index: 1051;
}
.portfolio-modal .close-modal .lr .rl {
  height: 75px;
  width: 1px;
  background-color: #303D4E;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  z-index: 1052;
}

.portfolio-modal .modal-backdrop {
  opacity: 0;
  display: none;
}

.location img {
  max-width: 100%;
  border-radius: 5px;
}

.location p {
  margin-top: 1rem;
  text-align: center;
}

p.etcetera {
  margin: 4rem auto;
}

/* Scroll Top Button (mobile) */
.scroll-top .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid var(--p400);
}

.scroll-top .btn i {
  color: var(--p400);
  padding: 0;
  padding-bottom: 4px;
}



/* Footer */

.copyright {
  padding: 2rem 0;
}