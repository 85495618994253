#professional div p.company a {
  color: var(--s500);
}

section#contact .btn-social.btn-outline {
  color: #fff;
  border: 2px solid #fff;
}

section#contact .btn-social.btn-outline:hover {
  color: var(--s400);
  border: 2px solid var(--s400);
}

.main-contact a {
  text-shadow: none;
}

.main-contact ul li a.btn-social.btn-outline {
  border: 2px solid var(--p500);
}

.main-contact ul li a.btn-social.btn-outline i {
  color: var(--p500);
}

.main-contact ul li:hover a.btn-social.btn-outline {
  border: 2px solid var(--s500);
  background: var(--s500);
}

.main-contact ul li:hover a.btn-social.btn-outline i {
  color: #000;
}

.main-contact ul li a:hover, .contact a:hover {
  text-shadow: none;
  background: none;
  text-decoration: none;
}

header, footer {
  background-size: cover;
}

.display {
  color: #fff;
  font-size: var(--font-sm);
}

.main-contact {
  margin-top: 30px;
}

.main-contact #display {
  font-size: var(--font-xs);
  margin-top: 30px;
}

.profile {
  max-height: 35rem;
  margin-bottom: 6rem;
  box-shadow: 0 0 15px #000;
}

.photo {
  border: 10px solid #fff;
  border-bottom-width: 50px;
}

.tilted {
  transition: all 1s ease-out;
  transform: rotate(3deg);
}

.tilted:hover {
  transition: all .1s ease-in;
  transform: rotate(0);
}

.img-shadow {
  box-shadow: 0 0 25px #000;
}

body {
  background-color: #292825;
  background-image: url("background.729b8fee.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

section {
  color: #fff;
  text-shadow: 0 0 10px #000;
  padding-top: 5rem;
}

section h2 {
  padding: 2rem;
}

#skills .skills-cards-container .skills-card ul {
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 0;
  display: flex;
}

@media (width <= 720px) {
  body {
    min-height: 120vw;
  }

  .img-responsive {
    max-width: 75%;
  }
}

@media (width >= 721px) {
  #skills .skills-cards-container {
    max-width: var(--width-limit);
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin: auto;
    display: flex;
  }

  #skills .skills-cards-container .skills-card {
    flex: 1;
  }

  ul.powered li a img:hover {
    filter: none;
    mix-blend-mode: normal;
  }
}

@media (width <= 1280px) {
  #skills .skills-cards-container {
    flex-direction: column;
    display: flex;
  }
}

.skills {
  font-size: var(--font-sm);
}

#skills h2 {
  color: var(--p500);
}

#skills .section-content {
  padding-top: 3rem;
}

.skillstitle {
  text-align: center;
  font-size: var(--font-md);
  border: 2px solid var(--p200);
  color: var(--p500);
  border-radius: 3px;
  padding: 1rem;
  font-weight: bold;
}

.skills > ul {
  padding: 0;
}

.skills > ul > li {
  color: #f9f9f9;
  line-height: 2em;
  font-size: var(--font-xs);
  text-align: center;
  list-style: none;
}

#contact .contact-link {
  color: var(--ps00);
}

#contact .contact-link:hover {
  color: var(--p500);
}

.contact-link, .contact-link:hover {
  color: var(--s500);
  text-decoration: none;
}

.hidden-text {
  justify-content: center;
  align-items: center;
  height: 36px;
  display: flex;
}

.separator {
  margin: 15px 0;
}

.star-separator {
  text-align: center;
}

#main {
  text-shadow: 0 0 10px #000;
  background: none;
}

#display {
  color: #ccc;
  margin-top: 20px;
}

#professional {
  text-shadow: 0 0 10px #000;
  background: none;
}

#professional h2 {
  color: var(--p500);
}

#professional .entry {
  margin: 4rem auto;
}

#professional .entry p.position {
  font-size: var(--font-md);
  color: var(--p500);
  margin: 1rem;
  font-weight: bold;
}

#professional .entry p.company a {
  color: var(--p400);
  font-size: var(--font-sm);
}

#professional .entry p.company a:hover {
  color: var(--s500);
  font-size: var(--font-sm);
}

#professional .entry p.date {
  color: var(--n800);
  font-style: normal;
}

#professional .entry.current {
  background-color: #fff3;
  margin: 4rem auto;
  padding: 2rem;
}

#professional .entry.current p.position {
  font-size: var(--font-lg);
}

#professional .entry.current p.company, #professional .entry.current p.date {
  font-size: var(--font-md);
}

#academic h2 {
  color: var(--s500);
}

#academic .entry {
  padding: 2rem;
}

#academic .entry p.position {
  font-size: var(--font-md);
  color: var(--s500);
  font-weight: bold;
}

#academic .entry p.date {
  color: var(--n800);
  font-size: var(--font-sm);
}

#contact {
  padding-bottom: 10rem;
}

#contact h2 {
  color: var(--s400);
}

p.small {
  font-size: var(--font-xs);
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

hr.star-light, hr.star-primary {
  text-align: center;
  border: none;
  border-top: 5px solid;
  max-width: 250px;
  margin: 25px auto 30px;
  padding: 0;
}

hr.star-light:after, hr.star-primary:after {
  content: "";
  font-display: swap;
  padding: 0 .25em;
  font-family: FontAwesome;
  font-size: 2em;
  display: inline-block;
  position: relative;
  top: -.8em;
}

hr.star-light {
  border-color: #fff;
}

hr.star-light-main:after {
  color: #fff;
  background-color: #454e5f;
}

hr.star-light-professional:after {
  color: #fff;
  background-color: #626a7a;
}

hr.star-light-academic:after {
  color: #fff;
  background-color: #74627a;
}

hr.star-light-skills:after {
  color: #fff;
  background-color: #715173;
}

hr.star-primary {
  border-color: #303d4e;
}

hr.star-primary:after {
  color: #303d4e;
  background-color: #fff;
}

.img-centered {
  margin: 0 auto;
}

header {
  text-align: center;
  color: #fff;
}

header .container {
  padding-top: 85px;
}

header img {
  margin: 0 auto 20px;
  display: block;
}

header .intro-text .name {
  text-transform: uppercase;
  font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 700;
  display: block;
}

header .intro-text .skills {
  font-size: 1.25em;
  font-weight: 300;
}

@media (width >= 768px) {
  header .container {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  header .intro-text .name {
    font-size: 2em;
  }

  header .intro-text .skills {
    font-size: 1.75em;
  }
}

@media (width <= 767px) {
  #side-menu {
    border-top: 2px solid var(--p500);
    transition: all .2s;
    transition: all ease-in 2. s;
    background-color: #0000;
    width: 100%;
    height: 0;
    padding-left: 3rem;
    position: fixed;
    right: -100%;
  }

  #side-menu.slided {
    border-top: 2px solid var(--s500);
    background-color: #000000bf;
    height: 100%;
    right: 0;
  }

  #page-content.slided {
    left: -100%;
  }
}

@media (width >= 767px) {
  #side-menu {
    flex-direction: row;
    justify-content: right;
    gap: 4rem;
    display: flex;
  }
}

section.success {
  color: #fff;
  background: #454e5f;
}

section.success a, section.success a:hover, section.success a:focus, section.success a:active, section.success a.active {
  color: #303d4e;
  outline: none;
}

#portfolio .portfolio-item {
  margin: 0 0 15px;
  right: 0;
}

#portfolio .portfolio-item .portfolio-link {
  max-width: 400px;
  margin: 0 auto;
  display: block;
  position: relative;
}

#portfolio .portfolio-item .portfolio-link .caption {
  opacity: 0;
  background: #454e5fe6;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
}

#portfolio .portfolio-item .portfolio-link .caption:hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content {
  font-size: var(--font-sm);
  text-align: center;
  color: #fff;
  width: 100%;
  height: 20px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content i {
  margin-top: -12px;
}

#portfolio .portfolio-item .portfolio-link .caption .caption-content h3, #portfolio .portfolio-item .portfolio-link .caption .caption-content h4 {
  margin: 0;
}

#portfolio * {
  z-index: 2;
}

@media (width >= 767px) {
  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
}

.btn-outline {
  color: #fff;
  font-size: var(--font-sm);
  background: none;
  border: 2px solid #fff;
  margin-top: 15px;
  transition: all .3s ease-in-out;
}

.btn-outline:hover, .btn-outline:focus, .btn-outline:active, .btn-outline.active {
  background: #fff;
  border: 2px solid #fff;
}

.floating-label-form-group {
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  padding-bottom: .5em;
  position: relative;
}

.floating-label-form-group input, .floating-label-form-group textarea {
  z-index: 1;
  resize: none;
  background: none;
  border: none;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 1.5em;
  position: relative;
  box-shadow: none !important;
}

.floating-label-form-group label {
  z-index: 0;
  vertical-align: middle;
  vertical-align: baseline;
  opacity: 0;
  margin: 0;
  font-size: .85em;
  line-height: 1.76471em;
  transition: top .3s, opacity .3s;
  display: block;
  position: relative;
  top: 2em;
}

.floating-label-form-group-with-value label {
  opacity: 1;
  top: 0;
}

.floating-label-form-group-with-focus label {
  color: #454e5f;
}

form .row:first-child .floating-label-form-group {
  border-top: 1px solid #eee;
}

a.btn-social {
  color: #fff;
  text-align: center;
  font-size: var(--font-md);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
}

.btn:focus, .btn:active, .btn.active {
  outline: none;
}

.scroll-top {
  z-index: 1049;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.scroll-top .btn {
  font-size: var(--font-sm);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  line-height: 28px;
}

.scroll-top .btn:focus {
  outline: none;
}

.portfolio-modal .modal-content {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  background-clip: border-box;
  border: none;
  border-radius: 0;
  min-height: 100%;
  padding: 100px 0;
}

.portfolio-modal .modal-content h2 {
  margin: 0;
  font-size: 3em;
}

.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content .item-details {
  margin: 30px 0;
}

.portfolio-modal .close-modal {
  cursor: pointer;
  background-color: #0000;
  width: 75px;
  height: 75px;
  position: absolute;
  top: 25px;
  right: 25px;
}

.portfolio-modal .close-modal:hover {
  opacity: .3;
}

.portfolio-modal .close-modal .lr {
  z-index: 1051;
  background-color: #303d4e;
  width: 1px;
  height: 75px;
  margin-left: 35px;
  transform: rotate(45deg);
}

.portfolio-modal .close-modal .lr .rl {
  z-index: 1052;
  background-color: #303d4e;
  width: 1px;
  height: 75px;
  transform: rotate(90deg);
}

.portfolio-modal .modal-backdrop {
  opacity: 0;
  display: none;
}

.location img {
  border-radius: 5px;
  max-width: 100%;
}

.location p {
  text-align: center;
  margin-top: 1rem;
}

p.etcetera {
  margin: 4rem auto;
}

.scroll-top .btn {
  border: 2px solid var(--p400);
  background: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.scroll-top .btn i {
  color: var(--p400);
  padding: 0 0 4px;
}

.copyright {
  padding: 2rem 0;
}
/*# sourceMappingURL=style.0071f8bf.css.map */
